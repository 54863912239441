<template>
  <v-app>
    <div
      class="kt-content kt-content--fit-top kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
      id="kt_content"
    >
      <!-- begin:: Content -->

      <!-- begin:: hero -->
      <div class="kt-sc-license" style="background-image: url('/assets/media/bg/bg-9.jpg')">
        <div class="kt-container">
          <div class="kt-sc__top">
            <h3 class="kt-sc__title">Subscription Plan</h3>

          </div>
        </div>
      </div>

      <!-- end:: hero -->
      <div class="kt-negative-spacing--7"></div>

      <!-- begin:: infobox -->
      <div class="kt-grid__item">
        <div class="kt-container">
          <div class="kt-portlet">
            <div class="kt-portlet__body">
              <div class="kt-infobox">
                <div class="kt-infobox__body">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="kt-infobox__section">
                        <div class="kt-infobox__content">
                          <h2 class="kt-infobox__subtitle">Website Subscription Plan</h2>
                        </div>
                        <v-btn
                          text
                          :to="{ name: 'manage-websites', params: { domainname: site }}"
                          outlined
                          class="btn btn-lg btn-secondary-main"
                          style="background:transparent;"
                        >
                          <i class="fas fa-arrow-left"></i> Back
                        </v-btn>
                      </div>
                      <!-- <div class="kt-infobox__section">
                      <div class="kt-infobox__content kt-infobox__content--md">
                        <p>
                          The point here is that anyone can ramble on and on or even write brief statement about something that really
                          <a href="javascript:;">Download Sample</a>. If you want people to continue to follow you and your blog Your blog post fonts are automatically styled according to your site's theme. However, you can change the text font and format by selecting the text and clicking the options.
                        </p>
                      </div>
                      </div>-->
                      <div class="kt-infobox__section">
                        <div class="kt-infobox__content">
                          <div class="table-responsive">
                            <table class="table table-light table-light--info">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th class="table-center" v-if="subscriptionsize==4">Free</th>
                                  <th class="table-center" v-if="subscriptionsize>=3">Lite</th>
                                  <th class="table-center" v-if="subscriptionsize>=2">Standard</th>
                                  <th class="table-center" v-if="subscriptionsize>=1">Ecommerce</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Storage</td>
                                  <td class="table-center" v-if="subscriptionsize==4">500 MB</td>
                                  <td class="table-center" v-if="subscriptionsize>=3">5 GB</td>
                                  <td class="table-center" v-if="subscriptionsize>=2">10 GB</td>
                                  <td class="table-center" v-if="subscriptionsize>=1">10 GB</td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Business Listing</td>
                                  <td class="table-center" v-if="subscriptionsize==4">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=3">
                                    <i class="flaticon2-check-mark text-success"></i>
                                    <!-- <i class="flaticon2-cross text-danger"></i> -->
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=2">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=1">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Bandwidth</td>
                                  <td class="table-center" v-if="subscriptionsize==4">Unlimited</td>
                                  <td class="table-center" v-if="subscriptionsize>=3">Unlimited</td>
                                  <td class="table-center" v-if="subscriptionsize>=3">Unlimited</td>
                                  <td class="table-center" v-if="subscriptionsize>=3">Unlimited</td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Content Delivery Network</td>
                                  <td class="table-center" v-if="subscriptionsize==4">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=3">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=2">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=1">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Free Subdomain</td>
                                  <td class="table-center" v-if="subscriptionsize==4">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=3">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=2">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=1">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Ads</td>
                                  <td class="table-center" v-if="subscriptionsize==4">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=3">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=2">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=1">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Connect own domain</td>
                                  <td class="table-center" v-if="subscriptionsize==4">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=3">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=2">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=1">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Premium Plugins</td>
                                  <td class="table-center" v-if="subscriptionsize==4">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=3">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=2">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=1">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">SSL Certificate</td>
                                  <td class="table-center" v-if="subscriptionsize==4">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=3">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=2">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=1">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">E-commerce Support</td>
                                  <td class="table-center" v-if="subscriptionsize==4">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=3">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=2">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=1">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="kt-font-bold table-row-title"
                                  >Accept Khalti Payment by Yelko</td>
                                  <td class="table-center" v-if="subscriptionsize==4">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=3">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=2">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=1">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="kt-font-bold table-row-title"
                                  >Accept Khalti Payment - Business</td>
                                  <td class="table-center" v-if="subscriptionsize==4">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=3">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=2">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=1">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Accept Esewa Payment</td>
                                  <td class="table-center" v-if="subscriptionsize==4">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=3">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=2">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=1">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    class="kt-font-bold table-row-title"
                                  >Accept International Payment</td>
                                  <td class="table-center" v-if="subscriptionsize==4">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=3">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=2">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=1">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                </tr>
                                <tr>
                                  <td class="kt-font-bold table-row-title">Report & Analytics</td>
                                  <td class="table-center" v-if="subscriptionsize==4">
                                    <i class="flaticon2-cross text-danger"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=3">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=2">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                  <td class="table-center" v-if="subscriptionsize>=1">
                                    <i class="flaticon2-check-mark text-success"></i>
                                  </td>
                                </tr>
                                <tr v-if="!website.active_cart">
                                  <td class="kt-font-bold table-row-title"></td>
                                  <td
                                    class="kt-font-bold table-row-title"
                                    v-if="subscriptionsize==4"
                                  >
                                    <v-btn
                                      @click="checkPlanValidity(subscriptions[0] , 'new')"
                                      class="btn btn-info"
                                    >Add To Cart [Free]</v-btn>
                                  </td>

  <!-- <pre>{{subscriptions[0].symbol}}</pre> -->
                                  <td class="table-center" v-if="subscriptionsize>=3">
                                    <v-btn
                                      v-if="subscriptionsize==3"
                                      @click="(website.subscription.id)?checkPlanValidity(subscriptions[0] , 'renew'):checkPlanValidity(subscriptions[0] , 'new')"
                                      class="btn btn-info"
                                    >{{website.subscription.id ?'RENEW':"Add To Cart"}} [{{subscriptions[0].symbol}}{{subscriptions[0].preferred_price[0].price}}/year]</v-btn>
                                    <v-btn
                                      v-else
                                      @click="(website.subscription.id)?checkPlanValidity(subscriptions[1] , 'renew'):checkPlanValidity(subscriptions[1] , 'new')"
                                      class="btn btn-info"
                                    >{{website.subscription.id ?'RENEW':"Add To Cart"}} [{{subscriptions[1].symbol}}{{subscriptions[1].preferred_price[0].price}}/year]</v-btn>
                                  </td>

                                  <td class="table-center" v-if="subscriptionsize>=2">
                                    <v-btn
                                      class="btn btn-info"
                                      v-if="subscriptionsize==2"
                                      @click="(website.subscription.id)?checkPlanValidity(subscriptions[0] , 'renew'):checkPlanValidity(subscriptions[0] , 'new')"
                                    >{{website.subscription.id ?'RENEW':"Add To Cart"}} [{{subscriptions[0].symbol}}{{subscriptions[0].preferred_price[0].price}}/year]</v-btn>
                                    <v-btn
                                      class="btn btn-info"
                                      v-else-if="subscriptionsize==3"
                                      @click="(website.subscription.id)?checkPlanValidity(subscriptions[1] , 'renew'):checkPlanValidity(subscriptions[1] , 'new')"
                                    >{{website.subscription.id ?'RENEW':"Add To Cart"}} [{{subscriptions[1].symbol}}{{subscriptions[1].preferred_price[0].price}}/year]</v-btn>
                                    <v-btn
                                      class="btn btn-info"
                                      v-else
                                      @click="(website.subscription.id)?checkPlanValidity(subscriptions[2] , 'renew'):checkPlanValidity(subscriptions[2] , 'new')"
                                    >{{website.subscription.id ?'RENEW':"Add To Cart"}} [{{subscriptions[2].symbol}}{{subscriptions[2].preferred_price[0].price}}/year]</v-btn>
                                  </td>

                                  <td class="table-center" v-if="subscriptionsize>=1">
                                    <v-btn
                                      class="btn btn-info"
                                      v-if="subscriptionsize==4"
                                      @click="(website.subscription.id)?checkPlanValidity(subscriptions[3] , 'renew'):checkPlanValidity(subscriptions[3] , 'new')"
                                    >{{website.subscription.id ?'RENEW':"Add To Cart"}} [{{subscriptions[3].symbol}}{{subscriptions[3].preferred_price[0].price}}/year]</v-btn>
                                    <v-btn
                                      class="btn btn-info"
                                      v-else-if="subscriptionsize==3"
                                      @click="(website.subscription.id)?checkPlanValidity(subscriptions[2] , 'renew'):checkPlanValidity(subscriptions[2] , 'new')"
                                    >{{website.subscription.id ?'RENEW':"Add To Cart"}} [{{subscriptions[2].symbol}}{{subscriptions[2].preferred_price[0].price}}/year]</v-btn>
                                    <v-btn
                                      class="btn btn-info"
                                      v-else-if="subscriptionsize==2"
                                      @click="(website.subscription.id)?checkPlanValidity(subscriptions[1] , 'renew'):checkPlanValidity(subscriptions[1] , 'new')"
                                    >{{website.subscription.id ?'RENEW':"Add To Cart"}} [{{subscriptions[1].symbol}}{{subscriptions[1].preferred_price[0].price}}/year]</v-btn>
                                    <v-btn
                                      class="btn btn-info"
                                      v-else
                                      @click="(website.subscription.id)?checkPlanValidity(subscriptions[0] , 'renew'):checkPlanValidity(subscriptions[0] , 'new')"
                                    >{{website.subscription.id ?'RENEW':"Add To Cart"}} [{{subscriptions[0].symbol}}{{subscriptions[0].preferred_price[0].price}}/year]</v-btn>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <!-- <div class> -->
                          <!-- <v-col cols="12" align="center">
                            <v-img
                              width="200"
                              all
                              position="center"
                              class="img-fluid"
                              contain
                              src="/assets/media/illustrations/undraw_Checklist__re_2w7v.svg"
                            ></v-img>
                          </v-col>-->

                          <!-- <v-col cols="12" align="center">
                            <h2 class="text-success">You Have Already Subscibed</h2>
                            <p>
                              Your have
                              <strong>{{website.subscription.remaining}}</strong> days Remaining to Expire.
                            </p>
                          </v-col>-->
                          <!-- </div> -->
                        </div>
                      </div>
                      <div class="row">
                        <div
                          class="col-md-12 colsm-12col-lg-12 text-right"
                          v-if="website.is_custom_domain == 1 && !website.plan_id"
                        >
                          <v-btn
                            class="btn btn-info"
                            @click="continueWithSubDomain"
                          >Continue with subdomain</v-btn>
                        </div>
                      </div>
                      <!-- v-if="!website.subscription && !website.subscription.status" -->
                      <div class="kt-infobox__section" >
                        <div class="kt-infobox__content kt-infobox__content--md">
                          <p class="text-danger" v-if="website.active_cart">
                            <small>
                              *you have active cart continue to
                              <router-link :to="{name: 'cart'}">Cart.</router-link>
                            </small>
                          </p>
                          <p>Note: Price are exclusive of government service tax.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- end:: infobox -->

      <!-- end:: iconbox -->

      <!-- end:: Content -->
      <div>
        <v-dialog scrollable v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Choose any options</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-radio-group v-model="option" :mandatory="false">
                      <v-radio value="extend_days" v-if="remaining['remaining_days']">
                        <template v-slot:label>
                          <div>
                            <h4>Extend subscription</h4>
                            <!-- <br /> -->
                            <small
                              style="opacity:0.8"
                            >According the plan you have selected you still have {{remaining['remaining_days']}} days left that can be extended when it gets upgraded</small>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="deduct_price" v-if="remaining['equivalent_remaining_price'] && remaining['status']">
                        <template v-slot:label>
                          <div>
                            <h4>Deduct Price in cart</h4>
                            <small
                              style="opacity:0.8"
                            >According the plan you have selected you still have {{subscriptions[0].symbol}} {{remaining['equivalent_remaining_price']}} amount that can be deducted from your total</small>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                    <strong class="text-danger">*Please select any one option to proceed</strong>
                    <br>
                    <small><i class="text-danger">To get benefited with the above options you have to upgrade your subscription at least for year.</i></small>

                  </v-col>
                </v-row>
              </v-container>
              <!-- <pre>{{cart}}</pre> -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
              <v-btn
                :disabled="!option"
                color="blue darken-1"
                text
                @click="buildCartObject(selected_plan, purchase_type)"
              >Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </v-app>
</template>
<script>
import { mapActions } from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import SubscriptionService from "@/services/subscription/SubscriptionService";
import Website from "@/services/Websites/WebsiteService";
import Subdomain from "./SubDomain";

const websiteService = new Website();
const subscriptionService = new SubscriptionService();

export default {
  name: "subscription",
  data() {
    return {
      dialog: false,
      subscriptions: [],
      cart: {
        item_type: "website",
        purchase_type: null,
        terms: "12",
        website_id: null,
        plan_id: null,
        item_price: null,
        is_processed: 0,
        renewal_flag: null
      },
      site: null,
      website: {},
      subscriptionsize: null,
      option: null,
      remaining: [],
      selected_plan: {},
      purchase_type: "new"
    };
  },
  components: {
    Subdomain
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },

    store() {
      return this.$store.getters.currentUser.store_config;
    }
  },
  created() {
    this.site = this.$route.params.domainname;
    this.getWebsite();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Ivoice", route: "dashboard" }
    ]);
    this.getWebsiteSubscription();
  },
  methods: {
    ...mapActions(["ADD_TO_CART"]),
    getWebsite() {
      websiteService
        .show(this.site)
        .then(response => {
          this.website = response.data.website;
        })
        .catch(error => {});
    },
    getWebsiteSubscription() {
      subscriptionService
        .getWebsiteSubscription(this.site)
        .then(response => {
          this.subscriptions = response.data.plan;
          this.subscriptionsize = response.data.size;
        })
        .catch(error => {});
    },
    checkPlanValidity(item, purchase_type) {
      websiteService
        .remainingValididyCheck(this.site, item.id)
        .then(response => {
          // console.log(response)
          if (response) {
            this.dialog = true;
            this.remaining = response.data;
            this.selected_plan = item;
            this.purchase_type = purchase_type;
          } else {
            this.buildCartObject(item, purchase_type);
          }
        })
        .catch(error => {
          // console.log(error);
        });
    },
    buildCartObject(item, purchase_types) {
      this.cart = {
        item_type: "website",
        purchase_type: purchase_types,
        terms: "12",
        website_id: this.website.id,
        plan_id: item.id,
        item_price: item.preferred_price[0].price,
        is_processed: 0,
        renewal_flag: this.option
      };
      if (this.cart.renewal_flag === "extend_days") {
        this.cart.renewal_value = this.remaining["remaining_days"];
      }

      if (this.cart.renewal_flag === "deduct_price") {
        this.cart.renewal_value = this.remaining["equivalent_remaining_price"];
      }

      this.addToCart();
    },
    addToCart() {
      if (this.cart) {
        this.ADD_TO_CART(this.cart)
          .then(response => {
            setTimeout(() => {
              this.$snotify.success("Added to cart");
              this.$router.push({ name: "cart" });
            }, 2000);
          })
          .catch(error => {
            this.$snotify.error("Error occured");
          });
      }
    },
    continueWithSubDomain() {
      let website = {
        sub_domain_name: this.website.sub_domain_name,
        is_custom_domain: 0,
        id: this.website.id
      };
      setTimeout(() => {
        websiteService
          .update(this.site, website)
          .then(res => {
            this.$snotify.success("Continuing with sub domain");
            this.$router.push({
              name: "manage-websites",
              params: { domainname: this.website.sub_domain_name }
            });
          })
          .catch(err => {});
      }, 2000);
    }
  }
};
</script>
